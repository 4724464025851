import React, { Component } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Subscribe } from 'unstated';
import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import { constants } from '../../constants';

import '../styles/ExportPane.scss';


export default class SaveBtn extends Component {
  render() {
    return (
      <Subscribe to={[AudioRecorderContainer]}>
        {audioRecorder => {
          return (
              <Popup
                trigger={
                  // enclose button in span to allow popup in disabled state
                  <span>
                    {/* render the save button only if the recording is shorter than 30,000 milliseconds (30 s) */}
                    <Button
                      icon
                      size="tiny"
                      className="save-btn, btn-right"
                      disabled={
                        audioRecorder.isRecording() ||
                        audioRecorder.getAudioDataLength() <= 0 ||
                        this.props.link.length > 0
                      }
                      onClick={() => this.props.onSave(audioRecorder)}
                    >
                      {/* Color is red if recording exceeds length limit. Red-state button prompts warning panel. */}
                      <Icon 
                        fitted 
                        name="save" 
                        color={audioRecorder.getRecordingDuration() > constants.REC_DURATION_THRESHOLD_LEN ? "red" : null } 
                      />
                    </Button>
                  </span>
                }
                className="fitted-padding"
                content="Save MP3"
                position="bottom center"
                inverted
                size="tiny"
              />           
          );
        }}
      </Subscribe>
    );
  }
}

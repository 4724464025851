import lamejs from 'lamejs';
import { isMobile } from 'react-device-detect';
import { constants } from './constants';

/**
 * @name toMp3
 * @description Utility function to encode AudioBuffer (webAudio) to mp3
 * @param {AudioBuffer} audioBuffer
 * @returns {Blob} blob object
 */
const toMp3 = audioBuffer => {
  let sample_rate = constants.SAMPLE_RATE;
  if (isMobile) { sample_rate = constants.SAMPLE_RATE_MOBILE; }

  let channels = 1; //1 for mono or 2 for stereo
  let kbps = 128; //encode 128kbps mp3
  let mp3encoder = new lamejs.Mp3Encoder(channels, sample_rate, kbps);
  let mp3Data = [];

  let samples = audioBuffer.getChannelData(0).map(function(ele) {
    return ele * 32767.5;
  });

  let sampleBlockSize = 1152; // can be anything but make it a multiple of 576 to make encoders life easier

  // Because current mp3 encode/decode shifts the whole recording to the right, 
  // we add some empty samples at the end to prevent loss of data. During decoding
  // we remove these samples at the beginning. 
  let newSample = new Float32Array(samples.length + MP3_EMPTY_TAIL_LENGTH).fill(0.0);
  newSample.set(samples);

  let mp3buf;
  for (let i = 0; i < newSample.length; i += sampleBlockSize) {
    let sampleChunk = newSample.subarray(i, i + sampleBlockSize);
    mp3buf = mp3encoder.encodeBuffer(sampleChunk);
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }
  }
  mp3buf = mp3encoder.flush(); // finish writing mp3

  if (mp3buf.length > 0) {
    mp3Data.push(new Int8Array(mp3buf));
  }

  let blob = new Blob(mp3Data, { type: 'audio/mp3' });
  return blob;
};

const clamp = (x, minX, maxX) => {
  return Math.max(minX, Math.min(maxX, x));
};

// Constant Variables 
const MP3_EMPTY_TAIL_LENGTH = 700; // Hard code the number of empty samples to be added when exporting mp3. Use 1000 for mflow version, 700 for normal version. 

export { toMp3, clamp };

import React, { Component } from 'react';
import { Button, Menu, Input, Popup } from 'semantic-ui-react';

class MultiplyInput extends Component {
    render() {
        return (
            <Menu.Item verticalAlign="top" className="menu-item-top-div repeat">
                <span>
                    {/* <Header as="h6" size="tiny" className="top-menu-header borderless">{this.props.header}</Header> */}
                    <Input size="tiny" transparent  type="text" value="1.2" action>
                    <Popup trigger = {
                            <span>
                                <Button disabled = {this.props.disabled} attached="left" icon size="tiny" onClick={() => this.props.onTimesClick()}>
                                    Repeat
                                </Button>
                            </span>
                        } 
                        content="Double"
                        className="fitted-padding"
                        position="bottom center"
                        inverted
                        size="tiny"/>
                        <input 
                            size='5'
                            style={{textAlign: 'center'}} 
                            value={this.props.getValue()}
                            onChange={e => this.props.onChange(e)}
                            disabled={this.props.disabled? "disabled" : ""}
                            onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                this.props.onEnter();
                            }
                            }}
                        />
                       
                    </Input>
                </span>
            </Menu.Item>
        )
    }
}

export default MultiplyInput;
import React, { Component } from 'react';

import { Segment, Menu, Button, Icon, Transition } from 'semantic-ui-react';

import '../styles/MusiscopeControls.scss';
// Using an ES6 transpiler like Babel
// To include the default styles
// import 'react-rangeslider/lib/index.css';

// Settings icon
// import { FaSlidersH } from "react-icons/fa";

// Musiscope Controls Class that renders all of the sound controls and uses the
// React Context API to hook up their functionality to the main state in app.js
// Which passes the controls down to Musiscope index.js
class MusiscopeControls extends Component {
  render() {
    return (
      <Segment compact className="menu-pane-container">
        <Menu className="menu-pane">
          {/** Controls Section 1 * */}
          <Menu.Item className="vert">
            {/* <div className="menu-header">Sound</div> */}
            {/* </Menu.Item> */}

            {/** Microphone Gain* */}
            {/* <Menu.Item className="vert">
										<div className="menu-header">Microphone Gain</div>
										<br></br>
										<Slider
										min={1}
										max={100}
										value={context.state.microphoneGain}
										onChange={context.handleGainChange}
										tooltip={false}
										className="gain-slider"/>
										<div>
											{context.state.microphoneGain}
										</div>
									</Menu.Item> */}
            <br></br>
            {/** Output Volume * */}
            {/* <Menu.Item className="vert">
										<div className="menu-header">Output Volume</div>
										<Slider
										min={1}
										max={100}
										value={context.state.outputVolume}
										onChange={context.handleOutputVolumeChange}
										tooltip={context.state.isStarted}
										className="slider"/>
										<div>
											{context.state.outputVolume}
										</div>
									</Menu.Item> */}
            {/* </Menu.Item> */}
          </Menu.Item>

          {/** Controls Section 2 * */}
          <Menu.Item className="vert no-line no-padding">
            {/* <div className="menu-header">Scales</div> */}
            <br></br>
            {/* <SelectionControls
              selection={this.props.selection}
              onPlaySelection={this.props.onPlaySelection}
              stopAllPlayers={this.props.stopAllPlayers}
              players={this.props.selectionPlayers}
            /> */}
          </Menu.Item>

          {/** Controls Section 3 * */}
          <Menu.Item className="vert">{/* <div className="menu-header"></div> */}</Menu.Item>
          <Menu.Item className="vert no-line no-padding">
            <Transition
              // visible={context.state.effectVisibility}
              animation="fade right"
              duration={400}
            >
              <div></div>
            </Transition>
          </Menu.Item>
        </Menu>
        <div className="close-menu-container">
          <Button icon onClick={this.props.closeMenu} className="close-menu">
            <Icon fitted name="angle double up" size="large" />
          </Button>
        </div>
      </Segment>
    );
  }
}

export default MusiscopeControls;

import React, { useEffect } from 'react';
import * as Fathom from 'fathom-client';
import Home from './components/Home';
import './App.scss';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-WVJRWD6'
}
//Initialize Google Tag Manager
TagManager.initialize(tagManagerArgs)

// Creating the dataLayer
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  'event': 'Pageview'
});


function  App() {
  // NOTE: Important effect for analytics tracking
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Fathom.load();
      Fathom.setSiteId(process.env.REACT_APP_FATHOM_SITE_ID);
      Fathom.trackPageview();
    }
  }, []);
  

  return (
    <div className="app">
      <Home />
    </div>
  );
}

export default App;

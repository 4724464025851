/**********************
 * Filename: UploadBtn.js
 * Description: Upload Button component for uploading mp3 file into Oscilloscope.
 * Date Created: Jan. 2023
 */
import React, { Component } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Subscribe } from 'unstated';
import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import WaveViewerContainer from '../../containers/WaveViewerContainer';
import MementoContainer from '../../containers/MementoContainer';

import '../styles/ExportPane.scss';


export default class UploadBtn extends Component {
  constructor(props){
    super(props);
    this.fileUploadRef = React.createRef();
  }

  render() {
    return (
      <Subscribe to={[AudioRecorderContainer, MementoContainer, WaveViewerContainer]}>
        {(audioRecorder, mContainer, wvContainer) => {
          return (
            <Popup
              trigger={
                // enclose button in span to allow popup in disabled state
                <span>
                  <Button
                    size="tiny"
                    icon
                    className="upload-btn btn-left"
                    disabled={ audioRecorder.isRecording() }
                    onClick={() => this.fileUploadRef.current.click()}
              >
                <Icon fitted name="upload" />
                <input
                  type="file" 
                  accept=".mp3"
                  onChange={(e) => this.props.onUpload(e, audioRecorder, wvContainer, mContainer)}
                  onClick={(e) => e.target.value = null}
                  ref={this.fileUploadRef}
                  hidden
                ></input>
              </Button>
                </span>
              }
              className="fitted-padding"
              content="Upload MP3"
              position="bottom center"
              inverted
              size="tiny"
            />
            
          );
        }}
      </Subscribe>
    );
  }
}

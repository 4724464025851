const OPTIMIZED_DRAWING_SCALE = 1 / 10;
const DOT_PLOT_SCALE = 1 / 2;
const HANDLE_EDGE_TOP_COLOR = 'rgba(240, 208, 34, 1)';

export class WaveData {
    constructor(wave_id, audiodata, is_selected = false, selection_start = 0, selection_end = 0, is_looping = false) {
      this.wave_id = wave_id;
      this.audiodata = audiodata;
      this.is_selected = is_selected;
      this.selection_start = selection_start;
      this.selection_end = selection_end;
      this.is_looping = is_looping;
    }
  
    getSVGForWave(scale, startIndex, endIdx, yOffset, height) {
        const signal = this.audiodata.slice(startIndex, endIdx);
        const len = endIdx - startIndex;
        let rectangles = '';
        let lines = '';

        if (scale > OPTIMIZED_DRAWING_SCALE) {
            // zoomed in
            
            for(let i = 0; i < len; i++) {
                const value = signal.get(i);
                if (value === 0) continue; // Hide empty recordings

                const x = i * scale;
                const y = height*value + yOffset;
                if(scale > DOT_PLOT_SCALE) {
                    rectangles += `M ${x-1.5} ${y-1.5} h 3 v 3 h -3 Z `
                }
                lines += `${lines.length === 0 ? 'M' : 'L'} ${x} ${y} `
            }
            
        } else {
            // zoomed out
            const rectangleChunk = Math.floor(1 / scale);

            for (let i = 0; i < len; i += rectangleChunk) {
                let minv = Infinity;
                let maxv = -Infinity;

                // get min and max of rectangleChunk
                for (let j = 0; j < rectangleChunk; j++) {
                    const value =  height * signal.get(i + j);
                    if (value < minv) minv = value;
                    if (value > maxv) maxv = value;
                }

                if (minv === Infinity || // hacky way to hide recording tails, because wvContainer.updateEnd doesn't re-render
                (maxv === 0 && minv === 0)) continue; // Hide empty recordings

                // draw rectangle between min and max
                rectangles += `M ${i * scale} ${minv - 1 + yOffset} h 2 v ${maxv - minv + 2} h -2 Z `

            }
        }
        return [rectangles, lines];
    }

    updateCtxUserSelection(ctx, startIdx, endIdx, scale, rectangleHeight, yPos) {
        // Selected and in view
        if(this.is_selected && this.selection_start < endIdx && this.selection_end > startIdx) {
            const x = (this.selection_start - startIdx) * scale;
            const w = (this.selection_end  - this.selection_start) * scale;
            ctx.fillRect(x, yPos, w, rectangleHeight);

            //  **********Draw yellow selection brackets on main canvas 
            let handleWidth = 10; // set length of the "staple"
            // if the selection is too narrow for the "staple" to render properly, limit the length of the "staple"
            if (w < handleWidth) {
                handleWidth = w / 2;
            }
            // line width for staples
            ctx.lineWidth = 4;
            // left upper staple
            ctx.strokeStyle = HANDLE_EDGE_TOP_COLOR;
            ctx.beginPath();
            ctx.moveTo(x+ handleWidth, yPos);
            ctx.lineTo(x, yPos );
            ctx.lineTo(x, yPos + rectangleHeight);
            ctx.lineTo(x+ handleWidth, yPos + rectangleHeight);
            ctx.stroke();

            // right upper staple
            ctx.strokeStyle = HANDLE_EDGE_TOP_COLOR;
            ctx.beginPath();
            ctx.moveTo(x + w - handleWidth, yPos);
            ctx.lineTo(x + w,  yPos);
            ctx.lineTo(x + w, yPos + rectangleHeight);
            ctx.lineTo(x + w - handleWidth, yPos + rectangleHeight);
            ctx.stroke();
        }
    }
}

import React, { Component } from 'react';
import { Button, Menu, Icon, Input, Header } from 'semantic-ui-react';

class PlusMinusInput extends Component {
    render() {
        return (
            <Menu.Item verticalAlign="top" className="menu-item-top-div">
                <span>
                    <Header as="h6" size="tiny" className="top-menu-header borderless">{this.props.header}</Header>
                    <Input size="mini" transparent  type="text" value="1.2" action>
                        <Button disabled = {this.props.disabled} attached="left" icon size="mini" onClick={() => this.props.onMinusClick()}>
                            <Icon fitted name="minus circle"></Icon>
                        </Button>
                        <input 
                            size='7'
                            style={{textAlign: 'center'}} 
                            value={this.props.getValue()}
                            onChange={e => this.props.onChange(e)}
                            disabled={this.props.disabled? "disabled" : ""}
                            onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                this.props.onEnter();
                            }
                            }}
                        />
                        <Button disabled = {this.props.disabled} attached="right" icon size="mini" onClick={() => this.props.onPlusClick()}>
                            <Icon  fitted name="plus circle"></Icon>
                        </Button>
                    </Input>
                </span>
            </Menu.Item>
        )
    }
}

export default PlusMinusInput;
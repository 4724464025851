import React, { Component } from 'react';
import '../styles/mic-instr-modal.scss';

import { Subscribe } from 'unstated';
import { Icon, Button } from 'semantic-ui-react';

import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import MicPerm1 from '../../img/Microphone Permissions 1.png';
import MicPerm2 from '../../img/Microphone Permissions 2.png';
import MicPerm3 from '../../img/Microphone Permissions 3.png';
import { MicStateEnum } from '../../enums';

export default class MicInstructionsModal extends Component {
  // eslint-disable-next-line class-methods-use-this
  hideModal(e, audioRecorder) {
    e.preventDefault();
    audioRecorder.setMicPermission(MicStateEnum.DISABLED);
  }

  render() {
    return (
      <Subscribe to={[AudioRecorderContainer]}>
        {audioRecorder => {
          if (audioRecorder.getMicPermission() === MicStateEnum.DENIED) {
            return (
              <div className="modal">
                <div className="row">
                  <div className="column">
                    <div className="header">
                      <div className="title">
                        To Use the Oscilloscope:
                        <br></br>
                        <br></br>
                        <Icon name="info circle" color="red" />
                        <div className="subtitle">You will need to allow microphone access!</div>
                        <Icon size="big" name="long arrow alternate right" color="black" />
                      </div>
                      <Button className="modalBtn" onClick={e => this.hideModal(e, audioRecorder)}>
                        Close
                      </Button>
                    </div>
                  </div>
                  {/* <div class="step"> */}
                  <div className="column">
                    <p>
                      <b>1)</b> Look for the lock or info icon on the top left of the page, directly
                      to the left of your browser&apos;s address bar.
                    </p>
                    <img className="img" alt="" src={MicPerm1}></img>
                  </div>
                  {/* </div> */}
                  <div className="step">
                    <div className="row">
                      <p>
                        <b>2)</b> Click on the icon, then locate the &quot;Microphone&quot; field in
                        the menu.
                      </p>
                      <img className="img" alt="" src={MicPerm2}></img>
                    </div>
                  </div>
                  {/* <div class="step"> */}
                  <div className="column">
                    <p>
                      <b>3)</b> Click on the field corresponding to Microphone, then select &quot;
                      Allow &quot; in the dropdown options.
                    </p>
                    <img className="img" alt="" src={MicPerm3}></img>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            );
          }
          return <React.Fragment></React.Fragment>;
        }}
      </Subscribe>
    );
  }
}

import React, { Component } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import '../styles/top-menu.scss';
import { constants } from '../../constants';

import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';
// To incude custom style
import '../styles/rangeslider-custom.scss';
import { Subscribe } from 'unstated';

import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import MementoContainer from '../../containers/MementoContainer';
import WaveViewerContainer from '../../containers/WaveViewerContainer';

// declare the gain scale factor as a constant
const CENTIBEL_SCALE_FACTOR = 50;

export default class MicControls extends Component {
  constructor(props) {
    super(props);

    // set the initial state
    this.state = {
      enabled: false,
      stream: null,
      sliderInput: 0,
      min: -10,
      max: 10,
      isMicMonitored: false
    };
  }

  /**
   * @name gain()
   * @param {string} sliderInput Input of the slider received from the onChange event handler as a string
   * @returns Gain of the mic defined on the current min-max scale (input / max gain)
   * @information Current range of the gain: 0.02 cB to 50 cB (50 ** -1 to 50 ** 1)
   */
  gain(sliderInput) {
    const { max } = this.state;
    return parseInt(sliderInput) / parseInt(max);
  }

  changeAmplitude(arContainer, wvContainer, value, rawRatio = false) {
    let amplitudeRatio;
    if (rawRatio) {
      amplitudeRatio = value;
      arContainer.setAmplitudeRatio(
        (Math.log(value) / Math.log(constants.AMPLITUDE_LOG_RATIO) + 2) * 1000
      );
    } else {
      amplitudeRatio = constants.AMPLITUDE_LOG_RATIO ** (value / 1000 - 2);
      arContainer.setAmplitudeRatio(value);
    }
    const selection = wvContainer.getSelection();
    if (selection != null && selection.start !== selection.end) {
      arContainer.changeAmplitude(amplitudeRatio, wvContainer, selection.start, selection.end);
    } else {
      arContainer.changeAmplitude(amplitudeRatio, wvContainer);
    }
  }

  /**
   * @name stepMicGrain()
   * @description increases the mic gain of the input gain, in centi-bels
   *
   * Parameters:  @param e - Event handler that contains the slider input to update the gain to the new gain (in centi-bels)
   * Effects: Modifies this.mic
   */
  stepMicGain(value, audioRecorder) {
    // e.preventDefault();
    // const { updateInputGain } = this.props;

    let sliderInput = value;

    let gain = this.gain(sliderInput);
    audioRecorder.setInputGain(CENTIBEL_SCALE_FACTOR ** gain);

    this.setState({
      sliderInput: sliderInput
    });
  }

  /**
   * @name monitorMic()
   *
   */
  monitorMic(audioRecorder) {
    // incoming input for the checkbox of mic monitor
    if (!this.state.isMicMonitored) {
      const acx = audioRecorder.getContext();

      // set the gain of the monitor node
      audioRecorder.setMonitorGain(1);

      // connect the monitor node to the destination
      audioRecorder.connectMonitorNode(acx.destination);
    } else {
      // disconnect monitor node from destination once mic monitor is done
      audioRecorder.disconnectMonitorNode();
    }

    this.setState({
      isMicMonitored: !this.state.isMicMonitored
    });
  }

  render() {
    return (
      <Subscribe to={[AudioRecorderContainer,WaveViewerContainer,MementoContainer]}>
        {(audioRecorder,wvContainer,mContainer)=> (
          <div className="mic horiz">
            <Popup
              trigger={<Icon name="microphone" />}
              className="fitted-padding"
              content="Mic Gain"
              position="top center"
              inverted
              size="small"
            />
            {audioRecorder.isRecording() &&
            <Slider
              onChange={value => this.stepMicGain(value, audioRecorder)}
              min={this.state.min}
              max={this.state.max}
              value={this.state.sliderInput}
              tooltip={false}
              className="slider"
            />}

            {!audioRecorder.isRecording() &&
            <Slider
              min={20}
              max={4000}
              value={audioRecorder.getAmplitudeRatio()}
              onChange={value => {
                              this.changeAmplitude(audioRecorder, wvContainer, value);
                            }}
              tooltip={false}
              className="slider"
            />}
            <span>&nbsp;</span>
            <Popup
              trigger={
                <Button
                  toggle
                  active={this.state.isMicMonitored}
                  onClick={() => this.monitorMic(audioRecorder)}
                  circular
                  id="aware-btn"
                >
                  <Icon name="headphones" fitted />
                </Button>
              }
              className="fitted-padding"
              content="Headphones Live Mode"
              position="bottom center"
              inverted
              size="small"
            />
          </div>
        )}
      </Subscribe>
    );
  }
}

/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import '../styles/home.scss';
import Musiscope from '../Musiscope';
import 'semantic-ui-css/semantic.min.css';

import Welcome from './Welcome';
import '../styles/Welcome.scss';

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Welcome />
        <Musiscope />
      </div>
    );
  }
}

export default Home;

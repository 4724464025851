/**
 * WaveViewerWrapper.js
 * This component exists so as to reduce the re-renders of the WaveViewer component by passing in only the two required
 * props instead of subscribing to the containers from WaveViewer. Also, this wrapper ensures that the audioRecorder and
 * wvContainer can be accessed in WaveViewer's life-cycle methods.
 * reference: https://stackoverflow.com/questions/49809884/access-react-context-outside-of-render-function
 */

import React, { Component } from 'react';

import { Subscribe } from 'unstated';
import WaveViewer from './WaveViewer';
import WaveViewerContainer from '../../containers/WaveViewerContainer';
import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import MementoContainer from '../../containers/MementoContainer';

export default class WaveViewerWrapper extends Component {
  // eslint-disable-next-line class-methods-use-this

  render() {
    return (
      <Subscribe to={[AudioRecorderContainer, WaveViewerContainer, MementoContainer]}>
        {(audioRecorder, wvContainer, mContainer) => (
          <>
          <WaveViewer audioRecorder={audioRecorder} wvContainer={wvContainer} mContainer={mContainer} />
          </>
          
        )}
      </Subscribe>
    );
  }
}

import React, { Component } from 'react';
import { Button, Menu, Icon, Popup } from 'semantic-ui-react';
import '../styles/bottom-menu.scss';

import { Subscribe } from 'unstated';
import WaveViewerContainer from '../../containers/WaveViewerContainer';
import AudioRecorderContainer from '../../containers/AudioRecorderContainer';

import MicControls from '../Musiscope/MicControls';

// If we want to use a slider
// import Slider from 'react-rangeslider';
// To include the default styles
// import 'react-rangeslider/lib/index.css';
// To incude custom style
// import "../styles/rangeslider-custom.scss"

const DIRECTION = {
  /* Declared as an object to use as an enum */
  RIGHT: 'right',
  LEFT: 'left'
};

const SKIP_BTN_SPEED = 100; // num ms between each call of skip
const FAST_SKIP_BTN_SPEED = 25; // num ms between each call of move
const ZOOM_BTN_SPEED = 50; // num ms between each call of zoom

// Main Menu Class that renders children as individual tab panes
class BottomMenu extends Component {
  state = {
    fastBackwardBtn: false,
    backwardBtn: false,
    forwardBtn: false,
    fastForwardBtn: false,
    zoomout: false,
    zoomin: false
  };

  releaseAllMoveBtn = () => {
    if (this.state.fastBackwardBtn) {
      clearTimeout(this.state.fastBackwardBtn);
      this.setState({ fastBackwardBtn: false });
    }
    if (this.state.backwardBtn) {
      clearTimeout(this.state.backwardBtn);
      this.setState({ backwardBtn: false });
    }
    if (this.state.forwardBtn) {
      clearTimeout(this.state.forwardBtn);
      this.setState({ forwardBtn: false });
    }
    if (this.state.fastForwardBtn) {
      clearTimeout(this.state.fastForwardBtn);
      this.setState({ fastForwardBtn: false });
    }
  };

  handleZoomIn = (audioRecorder, WVContainer) => {
    if (!audioRecorder.getAudioDataLength()) {
      return;
    }

    if (!this.state.zoomin) {
      const timer = window.setInterval(() => {
        this.zoomWaveViewer(audioRecorder, WVContainer, 'in');
      }, ZOOM_BTN_SPEED);
      //this.zoomWaveViewer(audioRecorder, WVContainer, 'in');
      this.setState({ zoomin: timer });
    }
  };

  releasehandleZoomIn = () => {
    if (this.state.zoomin) {
      clearTimeout(this.state.zoomin);
      this.setState({ zoomin: false });
    }
  };

  handleZoomOut = (audioRecorder, WVContainer) => {
    if (!audioRecorder.getAudioDataLength()) {
      return;
    }

    if (!this.state.zoomout) {
      const timer = window.setInterval(() => {
        this.zoomWaveViewer(audioRecorder, WVContainer, 'out');
      }, ZOOM_BTN_SPEED);
      //this.zoomWaveViewer(audioRecorder, WVContainer, 'out');
      this.setState({ zoomout: timer });
    }
  };

  releasehandleZoomOut = () => {
    if (this.state.zoomout) {
      clearTimeout(this.state.zoomout);
      this.setState({ zoomout: false });
    }
  };

  /* handleZoom: handles zoom in a slider.
   * DEPRECIATED!! Originally in AudioRecorder.js, uses react refs instead of the 'unstated' containers
   */
  // handleZoom = value => {
  // 	let sliderInput = value;

  // 	// call WaveViewer's onWaveTimeChanges
  // 	this.waveViewerRef.current.onWaveTimeChange(sliderInput);

  // 	// get zoomSlider state and set the value for the slider
  // 	const { zoomSlider } = { ...this.state };
  // 	zoomSlider.value = sliderInput;
  // 	this.setState({
  // 		zoomSlider: zoomSlider
  // 	});
  // }
  /* DEPRECIATED!! USED WVCONTAINER NO NEED TO PUT IT HERE */
  // handleSeekToLive = () => {
  // 	this.waveViewerRef.current.seekToLive();
  // }

  onNavigateWaveform(audioRecorder, wvContainer, dir, x2 = false) {
    switch (dir) {
      case DIRECTION.LEFT:
        this.navigateWaveform(audioRecorder, wvContainer, DIRECTION.LEFT, x2);
        break;
      case DIRECTION.RIGHT:
        this.navigateWaveform(audioRecorder, wvContainer, DIRECTION.RIGHT, x2);
        break;
      default:
        break;
    }
  }

  onEndNavigateWaveform(dir, x2 = false) {
    switch (dir) {
      case DIRECTION.LEFT:
        this.endNavigate(DIRECTION.LEFT, x2);
        break;
      case DIRECTION.RIGHT:
        this.endNavigate(DIRECTION.RIGHT, x2);
        break;
      default:
        break;
    }
  }

  endNavigate(dir, x2 = false) {
    switch (dir) {
      case DIRECTION.LEFT:
        if (x2) {
          if (this.state.fastBackwardBtn) {
            clearTimeout(this.state.fastBackwardBtn);
            this.setState({ fastBackwardBtn: false });
          }
        } else if (this.state.backwardBtn) {
          clearTimeout(this.state.backwardBtn);
          this.setState({ backwardBtn: false });
        }
        break;

      case DIRECTION.RIGHT:
        if (x2) {
          if (this.state.fastForwardBtn) {
            clearTimeout(this.state.fastForwardBtn);
            this.setState({ fastForwardBtn: false });
          }
        } else if (this.state.forwardBtn) {
          clearTimeout(this.state.forwardBtn);
          this.setState({ forwardBtn: false });
        }
        break;
      default:
        break;
    }
  }

  navigateWaveform(audioRecorder, wvContainer, dir, x2) {
    // release all move button is called first
    this.releaseAllMoveBtn();
    // here x2 represents the 2x speed
    switch (dir) {
      case DIRECTION.LEFT:
        if (x2) {
          if (!this.state.fastBackwardBtn) {
            // WVContainer.skip(-128);
            const timer = window.setInterval(() => {
              const totalSamplePoints = audioRecorder.getTotalSamplePoints();
              wvContainer.skip(totalSamplePoints, -128);
            }, SKIP_BTN_SPEED);
            const totalSamplePoints = audioRecorder.getTotalSamplePoints();
            wvContainer.skip(totalSamplePoints, -128);
            this.setState({ fastBackwardBtn: timer });
          }
        } else if (!this.state.backwardBtn) {
          const timer = window.setInterval(() => {
            const totalSamplePoints = audioRecorder.getTotalSamplePoints();
            wvContainer.skip(totalSamplePoints, -4);
          }, FAST_SKIP_BTN_SPEED);
          const totalSamplePoints = audioRecorder.getTotalSamplePoints();
          wvContainer.skip(totalSamplePoints, -4);
          this.setState({ backwardBtn: timer });
        }
        break;
      case DIRECTION.RIGHT:
        if (x2) {
          if (!this.state.fastForwardBtn) {
            const timer = window.setInterval(() => {
              const totalSamplePoints = audioRecorder.getTotalSamplePoints();
              wvContainer.skip(totalSamplePoints, 128);
            }, SKIP_BTN_SPEED);
            const totalSamplePoints = audioRecorder.getTotalSamplePoints();
            wvContainer.skip(totalSamplePoints, 128);
            this.setState({ fastForwardBtn: timer });
          }
        } else if (!this.state.forwardBtn) {
          const timer = window.setInterval(() => {
            const totalSamplePoints = audioRecorder.getTotalSamplePoints();
            wvContainer.skip(totalSamplePoints, 4);
          }, FAST_SKIP_BTN_SPEED);
          const totalSamplePoints = audioRecorder.getTotalSamplePoints();
          wvContainer.skip(totalSamplePoints, 4);
          this.setState({ forwardBtn: timer });
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  zoomWaveViewer(audioRecorder, wvContainer, direction) {
    const totalSamplePoints = audioRecorder.getTotalSamplePoints();
    const recordingSamplePoints = audioRecorder.getRecordingSamplePoints();
    const isRecording = audioRecorder.isRecording();
    const cv = wvContainer.getCanvasReference();
    const delta = direction === 'in' ? 5 : -5;
    // let xPosition = Math.floor(this.props.cv.width / 2);

    const xPosition = wvContainer.getSelectionCenter(cv);
    if (xPosition <= 0) {
      wvContainer.zoom(totalSamplePoints, recordingSamplePoints, isRecording, delta, cv);
    } else {
      wvContainer.zoom(totalSamplePoints, recordingSamplePoints, isRecording, delta, cv, xPosition);
    }
  }

  // Renders the Bottom Menu Bar
  render() {
    return (
      <Subscribe to={[WaveViewerContainer, AudioRecorderContainer]}>
        {(WVContainer, audioRecorder) => ( 
          
          <div className="bottom-menu-container">
            <Menu color="#ABE2FB" className="bottom-menu" attached="top">
              {/* Zoom Slider */}
              <Menu.Item className="zoom vert">
                <div>Zoom</div>
                <div className="horiz margin-bottom">
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="big"
                          disabled = {audioRecorder.getWaveMapSize() <= 0 && !audioRecorder.isRecording()}
                          className="zoom-in btn-left"
                          onMouseDown={() => this.handleZoomOut(audioRecorder, WVContainer)}
                          onMouseUp={() => this.releasehandleZoomOut()}
                          onTouchStart={() => this.handleZoomOut(audioRecorder, WVContainer)}
                          onTouchEnd={() => this.releasehandleZoomOut()}
                          onMouseLeave={() => this.releasehandleZoomOut()}
                          onTouchLeave={() => this.releasehandleZoomOut()}
                        >
                          {/* <Icon fitted name="angle right" /> */}
                          <Icon fitted name="zoom-out" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Zoom Out"
                    position="top center"
                    inverted
                    size="small"
                  />
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="big"
                          className="zoom-in btn-right"
                          disabled = {audioRecorder.getWaveMapSize() <= 0 && !audioRecorder.isRecording()}
                          onMouseDown={() => this.handleZoomIn(audioRecorder, WVContainer)}
                          onMouseUp={() => this.releasehandleZoomIn()}
                          onTouchStart={() => this.handleZoomIn(audioRecorder, WVContainer)}
                          onTouchEnd={() => this.releasehandleZoomIn()}
                          onMouseLeave={() => this.releasehandleZoomIn()}
                          onTouchLeave={() => this.releasehandleZoomIn()}
                        >
                          {/* <Icon fitted name="angle right" /> */}
                          <Icon fitted name="zoom-in" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Zoom In"
                    position="top center"
                    inverted
                    size="small"
                  />

                  {/* <Icon fitted name="zoom-in" />&nbsp;&nbsp;
							<Slider
								onChange={this.props.handleZoom}
								min={this.props.zoomSlider.min}
								max={this.props.zoomSlider.max}
								value={this.props.zoomSlider.value}
								tooltip={false}
								className="slider"
							/> */}
                </div>
              </Menu.Item>

              <Menu.Item verticalAlign="top" className="MoveBtns vert">
                <div>Pan Controls</div>
                <div className="horiz margin-bottom">
                  <Popup
                    trigger={
                      <Button
                        icon
                        size="big"
                        className="fast-back btn-left"
                        disabled = {audioRecorder.isRecording() || audioRecorder.getWaveMapSize() <= 0}
                        onMouseDown={() =>
                          this.onNavigateWaveform(audioRecorder, WVContainer, DIRECTION.LEFT, true)
                        }
                        onMouseUp={() => this.onEndNavigateWaveform(DIRECTION.LEFT, true)}
                        onTouchDown={() =>
                          this.onNavigateWaveform(audioRecorder, WVContainer, DIRECTION.LEFT, true)
                        }
                        onTouchUp={() => this.onEndNavigateWaveform(DIRECTION.LEFT, true)}
                        onMouseLeave={() => this.onEndNavigateWaveform(DIRECTION.LEFT, true)}
                        onTouchLeave={() => this.onEndNavigateWaveform(DIRECTION.LEFT, true)}
                      >
                        {/* <Icon fitted name="angle double left" /> */}
                        <Icon fitted name="fast backward" />
                      </Button>
                    }
                    className="fitted-padding"
                    content="Skip Backwards"
                    position="top center"
                    inverted
                    size="small"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon
                        size="big"
                        className="back-btn btn-middle"
                        disabled = {audioRecorder.isRecording() || audioRecorder.getWaveMapSize() <= 0}
                        onMouseDown={() =>
                          this.onNavigateWaveform(audioRecorder, WVContainer, DIRECTION.LEFT, false)
                        }
                        onMouseUp={() => this.onEndNavigateWaveform(DIRECTION.LEFT, false)}
                        onTouchDown={() =>
                          this.onNavigateWaveform(audioRecorder, WVContainer, DIRECTION.LEFT, false)
                        }
                        onTouchUp={() => this.onEndNavigateWaveform(DIRECTION.LEFT, false)}
                        onMouseLeave={() => this.onEndNavigateWaveform(DIRECTION.LEFT, false)}
                        onTouchLeave={() => this.onEndNavigateWaveform(DIRECTION.LEFT, false)}
                      >
                        {/* <Icon fitted name="angle left" /> */}
                        <Icon fitted name="backward" />
                      </Button>
                    }
                    className="fitted-padding"
                    content="Look Backwards"
                    position="top center"
                    inverted
                    size="small"
                  />
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="big"
                          className="forward-btn btn-middle"
                          disabled = {audioRecorder.isRecording() || audioRecorder.getWaveMapSize() <= 0}
                          onMouseDown={() =>
                            this.onNavigateWaveform(
                              audioRecorder,
                              WVContainer,
                              DIRECTION.RIGHT,
                              false
                            )
                          }
                          onMouseUp={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, false)}
                          onTouchDown={() =>
                            this.onNavigateWaveform(
                              audioRecorder,
                              WVContainer,
                              DIRECTION.RIGHT,
                              false
                            )
                          }
                          onTouchUp={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, false)}
                          onMouseLeave={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, false)}
                          onTouchLeave={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, false)}
                        >
                          {/* <Icon fitted name="angle right" /> */}
                          <Icon fitted name="forward" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Look Forwards"
                    position="top center"
                    inverted
                    size="small"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon
                        size="big"
                        className="fast-forward btn-right"
                        disabled = {audioRecorder.isRecording() || audioRecorder.getWaveMapSize() <= 0}
                        onMouseDown={() =>
                          this.onNavigateWaveform(audioRecorder, WVContainer, DIRECTION.RIGHT, true)
                        }
                        onMouseUp={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, true)}
                        onTouchDown={() =>
                          this.onNavigateWaveform(audioRecorder, WVContainer, DIRECTION.RIGHT, true)
                        }
                        onTouchUp={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, true)}
                        onMouseLeave={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, true)}
                        onTouchLeave={() => this.onEndNavigateWaveform(DIRECTION.RIGHT, true)}
                      >
                        {/* <Icon fitted name="angle double right" /> */}
                        <Icon fitted name="fast forward" />
                      </Button>
                    }
                    className="fitted-padding"
                    content="Skip Forwards"
                    position="top center"
                    inverted
                    size="small"
                  />
                  <div>&nbsp;&nbsp;</div>
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="big"
                          className="live-btn btn-no-margin"
                          disabled = {audioRecorder.isRecording() || audioRecorder.getWaveMapSize() <= 0}
                          onClick={() =>
                            WVContainer.seekToLive(audioRecorder.getTotalSamplePoints())
                          }
                        >
                          <Icon fitted name="fast forward" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Go to End"
                    position="top center"
                    inverted
                    size="small"
                  />
                  <div>&nbsp;&nbsp;</div>
                  {/* If Lockwave is to be used in the future */}
                  {/* <Popup
								trigger={
									<span>
										<Button
											toggle
											icon
											active={this.props.lockwave}
											onClick={this.props.handleLockWave}
											id="lockwaveBtn"
											className="btn-no-margin"
										>
											{this.props.lockwave ? <Icon fitted name="lock" /> :
                          <Icon fitted name="unlock" />}
										</Button>
									</span>
								}
								className="fitted-padding"
								content="Lock Wave"
								position="top center"
								inverted
								size="small"
							/> */}
                </div>
              </Menu.Item>

              {/* Microphone Gain */}
              {(audioRecorder.getWaveMapSize() > 0 || audioRecorder.isRecording()) &&
              <Menu.Item verticalAlign="mid" className="Amplitude vert">
                <div>Amplitude</div>
                <MicControls />
              </Menu.Item>}
            </Menu>
          </div>

          
        )}
      </Subscribe>
    ); // end return
  }
}

export default BottomMenu;

import React from 'react';
import ReactDOM from 'react-dom';
import UNSTATED from 'unstated-debug';
import { Provider } from 'unstated';

import AudioRecorderContainer from './containers/AudioRecorderContainer';
import WaveViewerContainer from './containers/WaveViewerContainer';
import MementoContainer from './containers/MementoContainer';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

UNSTATED.logStateChanges = false;

// initialize the containers here
const audioRecContainer = new AudioRecorderContainer();
const wvContainer = new WaveViewerContainer();
const mementoContainer = new MementoContainer();

// aggregate the states by connecting the wvContainer to audioRecContainer
audioRecContainer.setWaveViewer(wvContainer);

ReactDOM.render(
  <Provider inject={[audioRecContainer, wvContainer, mementoContainer]}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';

import { Button, Icon } from 'semantic-ui-react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import '../styles/Welcome.scss';
import { isMobile } from 'react-device-detect';

class Welcome extends Component {
  autoFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
      document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }

    /*this.setState({ init: true });*/
  };

  constructor(props) {
    super(props);
    this.state = {
      instr: false,
      closing: false,
      init: false,
      fullscreen: true
    };
  }

  componentDidMount() {
    // Start timeout for instructions to close

    //setTimeout(this.handleClosing, 3000);
    if (isMobile) {
      this.autoFullScreen();
    }
    this.setState({ instr: true });
    //setTimeout(this.setState({ instr: true }), 3000);
  }

  componentWillMount() {}

  // Close instructions
  handleInstructionsClose = () => {
    // console.log('close');
    this.setState({ instr: false });
  };

  handleClosing = () => {
    // console.log('closing');
    this.setState({ closing: true });
    this.setState({ closingtimer: setTimeout(this.handleInstructionsClose, 2100) });
  };

  pauseClosing = () => {
    // console.log('stop closing');
    this.setState({ closing: false });
    clearTimeout(this.state.closingtimer);
  };

  // Maximizes screen
  toggleFullScreen = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
      this.setState({ fullScreen: true });
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      this.setState({ fullScreen: false });
    }
  };

  render() {
    return (
      <span>
        <DeviceOrientation lockOrientation={'portrait'}>
          {/* Will only be in DOM in landscape */}
          {
            <Orientation orientation="portrait" alwaysRender={true}>
              {/*document.documentElement.requestFullScreen ? (document.documentElement.requestFullScreen()) : 
        (document.documentElement.mozRequestFullScreen ? (document.documentElement.mozRequestFullScreen()) :
        (document.documentElement.webkitRequestFullScreen ? document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        ) : (null)))*/}
            </Orientation>
          }
          <Orientation orientation="portrait" alwaysRender={false}>
            {this.state.instr ? (
              <div>
                <div id="outside-instr" onClick={this.handleInstructionsClose}></div>
                <div
                  id="instructions-bg"
                  className={this.state.closing ? 'closing' : ''}
                  onMouseMove={this.pauseClosing}
                  //onMouseOut={this.handleClosing}
                >
                  <div id="instructions">
                    <p>Only visible in landscape. Please rotate your device.</p>
                  </div>
                </div>
              </div>
            ) : (
              <span id="instructionsClosed" />
            )}
          </Orientation>

          <Orientation orientation="landscape" alwaysRender={false}>
            {this.state.instr ? (
              <div>
                <div id="outside-instr" onClick={this.handleInstructionsClose}></div>
                <div
                  id="instructions-bg"
                  className={this.state.closing ? 'closing' : ''}
                  onMouseMove={this.pauseClosing}
                  //onMouseOut={this.handleClosing}
                >
                  <div id="close-instr" onClick={this.handleInstructionsClose}>
                    <Icon fitted link color="red" name="close" />
                  </div>
                  <div id="instructions">
                    <p>
                      Click <Icon fitted size="large" color="red" name="circle" /> to start
                      recording and viewing the waveform. Make sure to allow your device to use the
                      microphone.
                      <br />
                      Use <Icon fitted size="large" name="play" /> to play the whole recording. Use{' '}
                      <Icon fitted size="large" name="undo alternate" /> to play in a loop.
                    </p>
                    <p>
                      Use the <Icon fitted size="large" name="zoom-in" />/
                      <Icon fitted size="large" name="zoom-out" /> buttons to zoom in/out the
                      timescale.
                    </p>
                    <p>
                      Use <Icon fitted size="large" name="backward" /> /{' '}
                      <Icon fitted size="large" name="forward" /> to scroll through the data.
                    </p>
                    {/* <p>
                                Enable `&quot;`lock wave `&quot;` to make the viewing window attempt to lock onto a
                                waveform preventing it from sliding across the window.
                            </p> */}
                    {/*<p>
                  Click and drag on the viewer to select a portion of the waveform. You can play the
                  selection section or loop it by clicking the{' '}
                  <Icon fitted size="large" name="play circle" /> or{' '}
                  <Icon fitted size="large" name="undo alternate" /> buttons.
                </p>*/}
                    <p>
                      Watch a{' '}
                      <a href="https://listeningtowaves.com/tutorials" color="blue">
                        tutorial
                      </a>
                      .{' '}
                    </p>
                    <p>Enjoy! :D</p>
                  </div>
                </div>
              </div>
            ) : (
              <span id="instructionsClosed" />
            )}
          </Orientation>
        </DeviceOrientation>

        <Button icon onClick={this.toggleFullScreen} className="fullscreenbutton">
          {!this.state.fullScreen ? (
            <Icon fitted name="expand" color="orange" size="large" />
          ) : (
            <Icon fitted name="compress" color="orange" size="large" />
          )}
        </Button>
      </span>
    );
  }
}

export default Welcome;

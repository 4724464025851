import React, { Component } from 'react';
import '../styles/musiscope.scss';

import WaveViewerWrapper from '../WaveViewer/WaveViewerWrapper';
import TopMenu from '../Menu/Top-Menu';
import RightMenu from '../Menu/Right-Menu';
import BottomMenu from '../Menu/Bottom-Menu';
import MicInstructionsModal from './MicInstructionsModal';

class Musiscope extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div className="musiscope">
        <div className="AudioRecorder">
          <TopMenu />
          <WaveViewerWrapper />
          <RightMenu />
          <BottomMenu />
          <MicInstructionsModal />
        </div>
      </div>
    );
  }
}

export default Musiscope;

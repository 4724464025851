/*******************************
 * Filename: MementoContainer.js
 * Description: Container of memento for the undo & redo functionality. Takes 
 *  snapshots of audioRecorder and wvContainer, and navigates among them
 * Start Date: 3/10/2022
 ******************************/
import { Container } from 'unstated';
import { BufferedArray ,Wave} from './AudioRecorderContainer';
// import {cloneDeep} from 'lodash';

const MAX_SNAPSHOT_COUNT = 10;

class MementoContainer extends Container {
  constructor() {
    super();

    // Default snapshot before any operation/recording
    const defaultSnapshot = {
      // clip: new Float32Array(0),
      selection: {start: 0, end: 0},
      pointSelection: false,
      audioData: [],
      waveMap: [],
      selectedClipId: null,
      selectedClipIndex: null,
    }

    this.state = {
      snapshots: [
        defaultSnapshot
      ], // array of objects: { clip, selection, pointSelection, ...}. 5 snapshots max for now
      currentSnapshot: 0, // index pointing to current snapshot in snapshots[]
    }
  }

  /**
   * Takes a snapshot of the current audio, and save to snapshot array. Call this 
   * at the end of every audio-manipulating functions of button.
   * @param {*} audioRecorder audioRecorderContainer for audio record/edit functions
   * @param {*} wvContainer waveViewerContainer for wave visual functions
   */
  async takeSnapshot(audioRecorder, wvContainer) {
    let { snapshots, currentSnapshot } = this.state;

    const waveMap = await audioRecorder.getRawWaveMap()??[];
    const newWaveMap = new Map();
    waveMap.forEach(item => {
      console.log(item);
      newWaveMap.set(item.id, new Wave(item.audioData.copy(),0,0, item.id));
    })

    let audioData = await audioRecorder.getRawAudioData(); 
    if(!audioData){
      audioData = new BufferedArray(0);
    }
    console.log("audio data buffer", audioData);
    const newAudioData = audioData.copy();

    // create new snapshot object with data from audioRecorder and wvContainer
    let newObj = {
      // clip: audioRecorder.getClipData(),
      selection: wvContainer.getSelection(),
      pointSelection: wvContainer.getPointSelectionFlag(),
      audioData: newAudioData,
      waveMap: newWaveMap,
      selectedClipId: audioRecorder.getSelectedClipId(),
      selectedClipIndex: audioRecorder.getSelectedClipIndex(),
    }

    // clear everything beyond position currentSnapshot
    snapshots.splice(currentSnapshot+1, (snapshots.length - currentSnapshot - 1));
    // add new snapshot object to snapshots, possibly shifting everything if already full
    if(snapshots.length === MAX_SNAPSHOT_COUNT){
      snapshots.shift();
      snapshots.push(newObj);
    }else{
      snapshots.push(newObj);
      currentSnapshot++;
    }

    this.setState({
      snapshots,
      currentSnapshot,
    })
  }

  /**
   * Go to and restore the previous snapshot, if available.
   * @param {*} audioRecorder audioRecorderContainer for audio record/edit functions
   * @param {*} wvContainer waveViewerContainer for wave visual functions
   */
  undo(audioRecorder, wvContainer) {

    let { snapshots, currentSnapshot } = this.state;
    if (this.canUndo()) {
      // reduce currentSnapshot by 1 if possible
      currentSnapshot--;
      // restore current snapshot
      this.restore(snapshots[currentSnapshot], audioRecorder, wvContainer);
    }

    this.setState({
      currentSnapshot
    });
  }

  /**
   * Go to and restore the next snapshot, if available.
   * @param {*} audioRecorder audioRecorderContainer for audio record/edit functions
   * @param {*} wvContainer waveViewerContainer for wave visual functions
   */
  redo(audioRecorder, wvContainer) {

    let { snapshots, currentSnapshot } = this.state;

    if (this.canRedo()) {
      // increase currentSnapshot by 1 if possible
      currentSnapshot++;
      // restore current snapshot
      this.restore(snapshots[currentSnapshot], audioRecorder, wvContainer);
      this.setState({
        currentSnapshot,
      })
    }
  }

  /**
   * Restore the data of current snapshot including audio and visual data.
   * @param {*} snapshot The snapshot object to be restored
   * @param {*} audioRecorder audioRecorderContainer for audio record/edit functions
   * @param {*} wvContainer waveViewerContainer for wave visual functions
   */
  async restore(snapshot, audioRecorder, wvContainer){
    // set audioRecorder and wvContainer based on current snapshot
    if(snapshot.audioData && snapshot.audioData.length === 0){
      // same as onClearBtnClick(), reset everything in arContainer and wvContainer
      wvContainer.setCurrentSelectionPlayTime(0);

      // reset point selection flag
      wvContainer.setPointSelectionFlag(false);
      wvContainer.setManualPtSelection(true);

      // clear the selection
      wvContainer.setNewSelection(true);
      wvContainer.clearSelection();

      // reset bar
      wvContainer.setDisplayBar(false);

      // reset styles for save warning
      // document.getElementById("save-warning").disabled = true;
      // document.getElementById("save-warning").classList.add("disabled");
      // document.getElementById('export-tab-name').style.color = '';
    }else{
      await audioRecorder.restoreSnapshot(snapshot, wvContainer)
    }

    if(snapshot.selection.end - snapshot.selection.start <= 1){
      wvContainer.clearSelection();
    }else{
      let newSelection = JSON.parse(JSON.stringify(snapshot.selection));  // deep copy of selection
      wvContainer.setSelection(newSelection, audioRecorder.state.acx.sampleRate);
    }
    wvContainer.setPointSelectionFlag(snapshot.pointselection);
  }


  /**
 * Decide whether there's a previous state for undo. 
 * @returns true if can undo; false otherwise. 
 */
  canUndo(){
    let { snapshots, currentSnapshot } = this.state;
    if(currentSnapshot > 0 && snapshots.length > 0){
      return true;
    }else{
      return false;
    }
  }
  /**
   * Decide whether there's a next state for redo. 
   * @returns true if can redo; false otherwise.
   */
  canRedo(){
    let { snapshots, currentSnapshot } = this.state;

    if(currentSnapshot >= 0 && currentSnapshot < snapshots.length-1){
      return true;
    }else{
      return false;
    }
  }
  /**
   * Clear all the snapshots and pointers
   */
  reset(){
    this.setState({
      snapshots: [ this.defaultSnapshot ],
      currentSnapshot: 0,
    })
  }
  
}

export default MementoContainer;

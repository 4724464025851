/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Button, Menu, Modal } from 'semantic-ui-react';
import { constants } from '../../constants';

import '../styles/Right-menu.scss';
import Slider from 'react-rangeslider';
import { Subscribe } from 'unstated';
import MusiscopeControls from '../Musiscope/MusiscopeControls';
import ExportPane from '../Musiscope/ExportPane';

// import MicControls from '../Musiscope/MicControls';

// To include the default styles
import 'react-rangeslider/lib/index.css';
// To incude custom style
import '../styles/rangeslider-custom.scss';

import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import WaveViewerContainer from '../../containers/WaveViewerContainer';
import MementoContainer from '../../containers/MementoContainer';
import { isMobile } from 'react-device-detect';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

/*const options = [
  { key: 'Musiscope', text: '', value: 'Musiscope' },
  { key: 'Tutorial', text: 'Tutorial', value: 'Tutorial' },
  { key: 'Signal Generator', text: 'Signal Generator', value: 'Signal Generator' },
  { key: 'Spectrogram', text: 'Spectrogram', value: 'Spectrogram' }
];*/

const EDIT = {
  SHORTER: 'shorter',
  LONGER: 'longer',
  LEFT: 'left',
  RIGHT: 'right'
};

/*const options2 = [
  { key: 'Length', text: 'Length', value: 'Length' },
  { key: 'Speed', text: 'Speed', value: 'Speed' },
  { key: 'Amplitude', text: 'Amplitude', value: 'Amplitude' },
  { key: 'Reverse', text: 'Reverse', value: 'Reverse' },
  { key: 'Reverb', text: 'Reverb', value: 'Reverb' }
];*/

const scaleForShorteningSelection = 9 / 10;
export const REC_DURATION_THRESHOLD_LEN = 300000; // 300 seconds

// Main Menu Class that renders children as individual tab panes
class RightMenu extends Component {
  constructor(props) {
    super(props);

    this.exportPaneRef = React.createRef();

    // Top Menu's state that we have is relevant to Top Menu's UI.
    this.state = {
      isPlaying: false /* NOTE: Can be shifted to AudioRecContainer in the future */,
      activeItem: null,
      pane: null,
      selectionEdited: false, // whether the user has edited the selection since the last
      selectionLengthString: '',
      currentPlaying: {
        player: null,
        timerId: null
      },
      warningOpen: false,
      isQuantizeSelectionPopUpOpen: false,
      reverb: false,
      isReverbSettingsPopUpOpen: false,
      decayTime: 500, // this value divided by 1000 is decay time in seconds
      isLengthPopUpOpen: false,
      isSpeedPopUpOpen: false,
      isAmplitudePopUpOpen: false,
      openPopUp: '',
      inputLength: 0, // millisecond
      inputSpeed: 0, // how many times faster
      inputAmplitude: 0,
      inputRepeatTime: 0,
    };
  }

  handleOpen = () => {
    this.setState({ isQuantizeSelectionPopUpOpen: true });
  };

  handleClose = () => {
    this.setState({ isQuantizeSelectionPopUpOpen: false });
  };

  // Function that switches between Menu Panes (children components)

  handleItemClick = (e, { name, audioRecorder }) => {
    let pane = null;

    switch (name) {
      case 'control-panel':
        if (name !== this.state.activeItem) {
          pane = <MusiscopeControls closeMenu={this.closeMenu} />;
        } else {
          name = null;
        }
        break;
      case 'export':
        if (name !== this.state.activeItem) {
          // pane = null;
          pane = (
            <ExportPane
              ref={this.exportPaneRef}
              closeMenu={this.closeMenu}
              isPlaying={this.props.isPlaying}
            />
          );
          this.popupWarn(audioRecorder);
        } else {
          name = null;
        }
        break;
      default:
        pane = null;
    }
    this.setState({ activeItem: name, pane });
  };

  // Function that handles the close of the menu
  closeMenu = () => this.setState({ pane: null, activeItem: null });

  popupWarn(audioRecorder) {
    if (audioRecorder.getRecordingDuration() >= REC_DURATION_THRESHOLD_LEN) {
      console.log('Warning: Recording duration past 5 minutes cannot be saved!');
      this.setState({ warningOpen: true });
    }
  }

  /** Handle ExportPane Prop passing * */

  closePopupWarn() {
    this.setState({ warningOpen: false });
  }

  onDownloadButtonClick = () => {
    this.props.onDownloadButtonClick();
  };

  // Future Email Button Implementation
  onEmailButtonClick = () => {
    this.props.onEmailButtonClick();
  };

  /** end */

  /* For Pause Button functionality */
  // onPauseAllSelection(audioRecorderContainer) {
  //   const { currentPlaying } = this.state;
  //   // if currentPlaying is playing, stop the timer that removes from the selectionPlayers array
  //   if (currentPlaying.player && currentPlaying.timerId) {
  //     clearTimeout(currentPlaying.timerId);
  //   }

  //   audioRecorderContainer.pauseAllSelectionPlayers(); // stop all the running players

  //   this.setState({
  //     isPlaying: false
  //   });
  // }
  /**
   * Handles the behavior of the copy button.
   */
  onCopyBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    console.log(selection);
    audioRecorder.copySelection(selection.start, selection.end);
  }

  /**
   * Handles the behavior of the insert paste button.
   * If some snippet is selected, replace it with the copied snippet. If there's a point selection,
   * insert the copied snippet at the point.
   */
  onInsertPasteBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    if (wvContainer.getPointSelectionFlag() && wvContainer.getManualPtSelection()) {
      // if point selection, insert to start of selection
      audioRecorder.pasteToSelection(selection.start, selection.start, wvContainer);
    } else {
      // if not point selection, replace the selection
      audioRecorder.pasteToSelection(selection.start, selection.end, wvContainer);
    }
  }

  /**
   * Handles the behavior of the overlap paste button.
   * If some snippet is selected, overlap the length with the copied snippet. If there's a point selection,
   * overlap the copied snippet after the point.
   */
  onOverlapPasteBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    console.log(selection);
    if (wvContainer.getPointSelectionFlag() && wvContainer.getManualPtSelection()) {
      // if point selection, overlap after start of selection
      audioRecorder.overlapOnSelection(selection.start, null, wvContainer);
    } else {
      // if not point selection, overlap on top of the selection
      audioRecorder.overlapOnSelection(selection.start, selection.end, wvContainer);
    }
  }

  /**
   * Handles the behavior of the undo button.
   * If able to undo, roll back to previous snapshot.
   */
  onUndoBtnClick(audioRecorder, wvContainer, mContainer) {
    if (mContainer.canUndo()) {
      mContainer.undo(audioRecorder, wvContainer);
    }
  }

  /**
   * Handles the behavior of the redo button.
   * If able to redo, roll back to next snapshot.
   */
  onRedoBtnClick(audioRecorder, wvContainer, mContainer) {
    if (mContainer.canRedo()) {
      mContainer.redo(audioRecorder, wvContainer);
    }
  }

  /**
   * Handles the behavior of the Repeat button.
   * Copies a segment of recording multiple times immediately after the selection
   */
  async onRepeatBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    console.log(selection);
    if (this.state.inputRepeatTime > 0) {
      await audioRecorder.repeatSelection(
        selection.start,
        selection.end,
        wvContainer,
        this.state.inputRepeatTime
      );
    }
    this.resetRepeatTime();
  }

  newSelectionOnClick(wvContainer) {
    wvContainer.setNewSelection(true);
  }

  editSelection(audioRecorder, wvContainer, editType) {
    this.originselection = {
      start: wvContainer.getSelection().start,
      end: wvContainer.getSelection().end
    };
    const updateSelection = {
      start: this.originselection.start,
      end: this.originselection.end
    };
    const samplesOnScreen = wvContainer.getCanvasReference().width / wvContainer.getScale();
    const samplesInSelection = this.originselection.end - this.originselection.start;
    let keepPlayingToOldEnd = false;
    switch (editType) {
      case EDIT.SHORTER:
        updateSelection.end =
          updateSelection.start + samplesInSelection * scaleForShorteningSelection;
        break;
      case EDIT.LONGER:
        updateSelection.end =
          updateSelection.start + samplesInSelection / scaleForShorteningSelection;
        break;
      case EDIT.LEFT:
        // not allowing to go beyond the left edge of the screen
        if (updateSelection.start - samplesOnScreen / 50 > 0) {
          updateSelection.start -= samplesOnScreen / 50;
          updateSelection.end -= samplesOnScreen / 50;
        }
        keepPlayingToOldEnd = true;
        break;

      case EDIT.RIGHT:
        // not allowing to go beyond the end of the samples
        if (updateSelection.end + samplesOnScreen / 50 < wvContainer.getEnd()) {
          updateSelection.start += samplesOnScreen / 50;
          updateSelection.end += samplesOnScreen / 50;
        }
        keepPlayingToOldEnd = true;
        break;

      default:
        break;
    }
    wvContainer.setKeepPlayingToOldEnd(keepPlayingToOldEnd);
    this.setState({
      selectionEdited: true
    });
    // // Trigger Musiscope to set selection of buffers upon what the user has entered
    // setState is async call so need to use updateSelection rather than this.state.selection
    wvContainer.setSelection(updateSelection);
    wvContainer.setSelectionLengthString(
      wvContainer.getSelectionLength(audioRecorder.getSampleRate())
    );
  }

  quantizeSelection(audioRecorder, wvContainer, length) {
    this.originselection = {
      start: wvContainer.getSelection().start,
      end: wvContainer.getSelection().end
    };
    const updateSelection = {
      start: this.originselection.start,
      end: this.originselection.end
    };

    const keepPlayingToOldEnd = false;
    updateSelection.end = updateSelection.start + length * audioRecorder.getSampleRate();
    wvContainer.setKeepPlayingToOldEnd(keepPlayingToOldEnd);
    // // Trigger Musiscope to set selection of buffers upon what the user has entered
    // setState is async call so need to use updateSelection rather than this.state.selection
    this.setState({
      selectionEdited: true
    });
    wvContainer.setSelection(updateSelection, audioRecorder.getSampleRate());
    // wvContainer.setSelectionLengthString(wvContainer.getSelectionLength(audioRecorder.getSampleRate()));
  }

  toggleQuantizeSelection() {
    this.setState(state => ({
      isQuantizeSelectionPopUpOpen: !state.isQuantizeSelectionPopUpOpen
    }));
  }

  toggleReverbSettings = () => {
    if (this.state.openPopUp === 'reverb') {
      this.setState({
        openPopUp: ''
      });
    } else {
      this.setState({
        openPopUp: 'reverb'
      });
    }

    // this.setState(state => ({
    //   isReverbSettingsPopUpOpen: !state.isReverbSettingsPopUpOpen
    // }));
  };

  reverse(arContainer, wvContainer) {
    const selection = wvContainer.getSelection();
    if (selection != null && selection.start !== selection.end) {
      arContainer.reverse(selection.start, selection.end);
    } else {
      // revert entire track if there's no selection
      arContainer.reverse();
    }
  }

  resample(arContainer, wvContainer, value) {
    if (value < 100) {
      console.log('resample to a speed too slow');
      return;
    }
    arContainer.setResampleRatio(value);
    const selection = wvContainer.getSelection();
    let tempEnd = wvContainer.getEnd();
    if (selection != null && selection.start !== selection.end) {
      arContainer.resample(value / 1000, wvContainer, selection.start, selection.end, tempEnd);
    } else {
      // revert entire track if there's no selection
      arContainer.resample(value / 1000, wvContainer, 0, tempEnd, tempEnd);
    }
  }

  changeAmplitude(arContainer, wvContainer, value, rawRatio = false) {
    let amplitudeRatio;
    if (rawRatio) {
      amplitudeRatio = value;
      arContainer.setAmplitudeRatio(
        (Math.log(value) / Math.log(constants.AMPLITUDE_LOG_RATIO) + 2) * 1000
      );
    } else {
      amplitudeRatio = constants.AMPLITUDE_LOG_RATIO ** (value / 1000 - 2);
      arContainer.setAmplitudeRatio(value);
    }
    const selection = wvContainer.getSelection();
    if (selection != null && selection.start !== selection.end) {
      arContainer.changeAmplitude(amplitudeRatio, wvContainer, selection.start, selection.end);
    } else {
      arContainer.changeAmplitude(amplitudeRatio, wvContainer);
    }
  }

  handleReverbClick = () => {
    this.setState(prevState => ({ reverb: !prevState.reverb }));
  };

  handleLengthChange = (e, arContainer) => {
    this.setState({ inputLength: e.target.value });
    arContainer.setResetLengthDisplay(false);
  };

  handleSpeedChange = (e, arContainer) => {
    this.setState({ inputSpeed: e.target.value });
    arContainer.setResetResampleDisplay(false);
  };

  handleAmplitudeChange = (e, arContainer) => {
    this.setState({ inputAmplitude: e.target.value });
    arContainer.setResetAmplitudeDisplay(false);
  };

  handleRepeatTimeChange = e => {
    this.setState({ inputRepeatTime: e.target.value });
  };

  resetRepeatTime() {
    this.setState({ inputRepeatTime: 0 });
  }

  // handleLengthChangeSubmit = wvContainer => {
  //   wvContainer.setLen;
  // };

  /**
   * Applies reverb to selection or entire buffer in absence of selection
   */
  async applyReverb(audioRecorder, wvContainer) {
    // find selection indices
    const selection = wvContainer.getSelection();

    if (audioRecorder.getAudioDataLength() !== 0) {
      let selectionLength;
      if (selection.start !== selection.end) {
        selectionLength = (await audioRecorder.getBuffer(selection.start, selection.end)).length;
      } else {
        // 0 0 for entire track
        selectionLength = (await audioRecorder.getBuffer(0, 0)).length;
      }
      // construct offline context with reverb

      if (selectionLength !== 0) {
        const offlineCtx = new OfflineAudioContext(
          1,
          selectionLength,
          audioRecorder.getSampleRate()
        );

        // load impulse response from file
        const reverb = offlineCtx.createConvolver();
        const reverbGain = offlineCtx.createGain();
        reverbGain.value = 1.3;
        const response = await fetch(
          'https://soundbasketlisten.s3-us-west-1.amazonaws.com/rir_jack_lyons_lp2_96k.wav'
        );
        const arraybuffer = await response.arrayBuffer();
        reverb.buffer = await offlineCtx.decodeAudioData(arraybuffer);

        // connect nodes
        reverb.connect(reverbGain);
        reverbGain.connect(offlineCtx.destination);

        // apply reverb
        audioRecorder.applyEffect(offlineCtx, reverb, selection);
      }
    }
  }

  // Renders the top Menu Bar with tabs, microphone gain, and the control panel buttons
  // as well as the graph scale and which tab to render
  render() {
    return (
      <Subscribe to={[AudioRecorderContainer, WaveViewerContainer, MementoContainer]}>
        {(audioRecorder, wvContainer, mContainer) => (
          <div className="right-container">
            {audioRecorder.isRightMenuOpen() &&
            !audioRecorder.isRecording() && ( //!isMobile &&
                <Menu color="#ABE2FB" className="right-menu" attached="bottom" vertical>
                  {/* NOTE: Commented out Control Panel. Need to display it in the future */}
                  {/* <Menu.Item name='control-panel' active={activeItem === 'control-panel'} onClick={this.handleItemClick} className="tab-item" style={soundStyle} /> */}
                  {/* <Menu.Item name='advanced' active={activeItem === 'advanced'} onClick={this.handleItemClick} className="tab-item"/> */}

                  {/* REVAMPED BUTTONS */}

                  {/* Length Control */}
                  {!audioRecorder.isRecording() && ( //!isMobile &&
                    <Menu.Item>
                      <Button
                        className="top-menu-button"
                        onClick={() => {
                          if (this.state.openPopUp === 'length') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'length'
                            });
                          }
                          // this.setState(state => ({ isLengthPopUpOpen: !state.isLengthPopUpOpen }));
                        }}
                      >
                        Length
                      </Button>
                    </Menu.Item>
                  )}

                  {/* length = 1ms * (10/9) ^ slider_value */}
                  {this.state.openPopUp === 'length' && ( //!isMobile &&
                    <div>
                      <div
                        className="popup-mask"
                        onClick={() => {
                          this.setState({
                            openPopUp: ''
                          });
                        }}
                      ></div>
                      <div
                        style={{
                          top: '0px',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 2,
                          left: '-290px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          <span> &nbsp;Selection Length: </span>
                          <Slider
                            min={0}
                            max={70}
                            value={
                              Math.log(
                                wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                              ) / Math.log(1 / scaleForShorteningSelection)
                            }
                            onChange={value => {
                              wvContainer.updateSelectionLength(
                                audioRecorder,
                                value,
                                scaleForShorteningSelection,
                                audioRecorder.getSampleRate()
                              );

                              this.setState({
                                inputLength: (
                                  ((1 / scaleForShorteningSelection) ** value / 1000) *
                                  1000
                                ).toFixed(2) // display 2 decimal points
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />

                          {/* <span>{wvContainer.getSelectionLengthString()}&nbsp;&nbsp;</span> */}
                        </div>

                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetLengthDisplay()
                                ? wvContainer
                                    .getSelectionLength(audioRecorder.getSampleRate())
                                    .toFixed(2)
                                : //   Math.log(
                                  //     wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                                  //   ) / Math.log(1 / scaleForShorteningSelection)
                                  // ).toFixed(2)
                                  this.state.inputLength
                            }
                            onChange={e => this.handleLengthChange(e, audioRecorder)}
                          ></input>{' '}
                          ms
                        </label>
                        <button
                          onClick={() => {
                            const value =
                              Math.log(this.state.inputLength) /
                              Math.log(1 / scaleForShorteningSelection);
                            wvContainer.updateSelectionLength(
                              audioRecorder,
                              value,
                              scaleForShorteningSelection,
                              audioRecorder.getSampleRate()
                            );
                            wvContainer.setManualPtSelection(false);
                            //console.log("setPointSelectionFlag(false)");
                          }}
                        >
                          enter
                        </button>
                      </div>
                    </div>
                  )}

                  {!audioRecorder.isRecording() && ( //!isMobile &&
                    <Menu.Item>
                      <Button
                        onClick={() => {
                          if (this.state.openPopUp === 'speed') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'speed'
                            });
                          }

                          // this.setState(state => ({ isSpeedPopUpOpen: !state.isSpeedPopUpOpen }));
                        }}
                        className="top-menu-button"
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                      >
                        Speed
                      </Button>
                    </Menu.Item>
                  )}

                  {this.state.openPopUp === 'speed' && ( //!isMobile &&
                    <div>
                      <div
                        className="popup-mask"
                        onClick={() => {
                          this.setState({
                            openPopUp: ''
                          });
                        }}
                      ></div>
                      <div
                        style={{
                          top: '0px',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 2,
                          left: '-290px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          speed:
                          <Slider
                            min={200}
                            max={4000}
                            value={audioRecorder.getResampleRatio()}
                            onChange={value => {
                              this.resample(audioRecorder, wvContainer, value);
                              this.setState({
                                inputSpeed: audioRecorder.getResampleRatio() / 1000
                              });
                            }}
                            onChangeComplete={() =>
                              mContainer.takeSnapshot(audioRecorder, wvContainer)
                            }
                            tooltip={false}
                            className="slider"
                          />
                          {/* {audioRecorder.getResampleRatio() / 1000} */}
                        </div>

                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetResampleDisplay()
                                ? audioRecorder.getResampleRatio() / 1000
                                : this.state.inputSpeed
                            }
                            onChange={e => this.handleSpeedChange(e, audioRecorder)}
                          ></input>{' '}
                          x
                        </label>
                        <button
                          onClick={async () => {
                            await this.resample(
                              audioRecorder,
                              wvContainer,
                              this.state.inputSpeed * 1000
                            );
                            mContainer.takeSnapshot(audioRecorder, wvContainer);
                          }}
                        >
                          enter
                        </button>
                      </div>
                    </div>
                  )}

                  {!audioRecorder.isRecording() && ( //!isMobile &&
                    <Menu.Item>
                      <Button
                        onClick={() => {
                          // this.setState(state => ({ isAmplitudePopUpOpen: !state.isAmplitudePopUpOpen }));

                          if (this.state.openPopUp === 'amplitude') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'amplitude'
                            });
                          }
                        }}
                        className="top-menu-button"
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                      >
                        Amplitude
                      </Button>
                    </Menu.Item>
                  )}

                  {this.state.openPopUp === 'amplitude' && ( //!isMobile &&
                    <div>
                      <div
                        className="popup-mask"
                        onClick={() => {
                          this.setState({
                            openPopUp: ''
                          });
                        }}
                      ></div>
                      <div
                        style={{
                          top: '0px',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 2,
                          left: '-290px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          amplitude:
                          <Slider
                            min={20}
                            max={4000}
                            value={audioRecorder.getAmplitudeRatio()}
                            onChange={value => {
                              this.changeAmplitude(audioRecorder, wvContainer, value);
                              this.setState({
                                // inputAmplitude: audioRecorder.getAmplitudeRatio() /
                                // 1000
                                inputAmplitude:
                                  constants.AMPLITUDE_LOG_RATIO **
                                  (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                              });
                            }}
                            onChangeComplete={() =>
                              mContainer.takeSnapshot(audioRecorder, wvContainer)
                            }
                            tooltip={false}
                            className="slider"
                          />
                          {/* {audioRecorder.getAmplitudeRatio() / 1000} */}
                        </div>
                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetAmplitudeDisplay()
                                ? (
                                    constants.AMPLITUDE_LOG_RATIO **
                                    (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                                  ).toFixed(2)
                                : this.state.inputAmplitude
                            }
                            onChange={e => this.handleAmplitudeChange(e, audioRecorder)}
                          ></input>{' '}
                          x
                        </label>
                        <button
                          onClick={async () => {
                            await this.changeAmplitude(
                              audioRecorder,
                              wvContainer,
                              this.state.inputAmplitude,
                              true
                            );
                            mContainer.takeSnapshot(audioRecorder, wvContainer);
                          }}
                        >
                          enter
                        </button>{' '}
                      </div>
                    </div>
                  )}

                  {/* <MenuItem>
                  <Button onClick={() => this.toggleQuantizeSelection()}>Edit Selection</Button>
                </MenuItem> */}
                  <div
                    style={{
                      position: 'absolute',
                      display: this.state.isQuantizeSelectionPopUpOpen ? 'block' : 'none',
                      top: '0px',
                      left: '-290px',
                      zIndex: '1',
                      padding: '10px'
                    }}
                  >
                    <div style={{ backgroundColor: 'white', borderRadius: '.28571429rem' }}>
                      {/* <button onClick={() => this.newSelectionOnClick(wvContainer)}>
                      {' '}
                      new selection{' '}
                    </button> */}
                      <div>
                        <button
                          className="selectionButtons"
                          onClick={() => this.editSelection(audioRecorder, wvContainer, EDIT.LEFT)}
                        >
                          {' '}
                          &lt;-{' '}
                        </button>
                        <button
                          className="selectionButtons"
                          onClick={() => this.editSelection(audioRecorder, wvContainer, EDIT.RIGHT)}
                        >
                          {' '}
                          -&gt;{' '}
                        </button>
                        <button
                          className="selectionButtons"
                          onClick={() =>
                            this.editSelection(audioRecorder, wvContainer, EDIT.LONGER)
                          }
                        >
                          longer
                        </button>
                        <button
                          className="selectionButtons"
                          onClick={() =>
                            this.editSelection(audioRecorder, wvContainer, EDIT.SHORTER)
                          }
                        >
                          shorter
                        </button>
                      </div>
                    </div>
                  </div>

                  {!audioRecorder.isRecording() && ( //!isMobile &&
                    <Menu.Item>
                      <Button
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                        onClick={async () => {
                          await this.reverse(audioRecorder, wvContainer);
                          mContainer.takeSnapshot(audioRecorder, wvContainer);
                        }}
                        className="top-menu-button"
                      >
                        Reverse
                      </Button>
                    </Menu.Item>
                  )}

                  {/* Reverb crashed (even for the previous versions that used to work), so Reverb is temporarily disabled. */}
                  {/*!isMobile && 
                <Menu.Item>
                    <Button
                      toggle
                      disabled={
                        this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0
                      }
                      active={audioRecorder.isReverbOn()}
                      onClick={() => this.applyReverb(audioRecorder, wvContainer)}
                      className="top-menu-button"
                    >
                      Reverb
                    </Button>
                </Menu.Item>
                */}

                  {this.state.openPopUp === 'Reverse' &&
                    (this.reverse(audioRecorder, wvContainer), this.setState({ openPopUp: '' }))}
                  {this.state.openPopUp === 'Reverb' &&
                    (this.applyReverb(audioRecorder, wvContainer),
                    this.setState({ openPopUp: '' }))}

                  {!audioRecorder.isRecording() && ( //!isMobile &&
                    <Menu.Item>
                      <Button
                        onClick={() => {
                          if (this.state.openPopUp === 'repeat') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'repeat'
                            });
                          }

                          // this.setState(state => ({ isSpeedPopUpOpen: !state.isSpeedPopUpOpen }));
                        }}
                        className="top-menu-button"
                        disabled={
                          audioRecorder.isRecording() || // is recording
                          audioRecorder.getAudioDataLength() <= 0 || // no recording in the buffer
                          wvContainer.getSelection() == null || // no selection
                          wvContainer.getPointSelectionFlag() || // point selection
                          wvContainer.getSelection().end - wvContainer.getSelection().start <= 0 || // selection is not positive length
                          audioRecorder.getLastPlayer() !== undefined || // some clip is playing/looping
                          this.state.isPlaying // is playing the clip
                        }
                      >
                        Repeat
                      </Button>
                    </Menu.Item>
                  )}

                  {/* TODO: reset times */}
                  {/* Repeat function button */}
                  {this.state.openPopUp === 'repeat' && ( //!isMobile &&
                    <div>
                      <div
                        className="popup-mask"
                        onClick={() => {
                          this.setState({
                            openPopUp: ''
                          });
                        }}
                      ></div>
                      <div
                        style={{
                          top: '0px',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 2,
                          left: '-300px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <label>
                          <input
                            type="number"
                            value={this.state.inputRepeatTime}
                            onChange={e => this.handleRepeatTimeChange(e)}
                          ></input>{' '}
                          times
                        </label>
                        <button
                          onClick={async () => {
                            await this.onRepeatBtnClick(audioRecorder, wvContainer);
                            mContainer.takeSnapshot(audioRecorder, wvContainer);
                          }}
                        >
                          enter
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Modal for too long of recording duration warning */}
                  <Modal size={'tiny'} open={this.state.warningOpen}>
                    <Modal.Header>Warning!</Modal.Header>
                    <Modal.Content>
                      <p>
                        Recordings longer than 5 minutes are unable to be saved or downloaded in
                        order to preserve performance.
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        primary
                        onClick={() => this.closePopupWarn()}
                        icon="checkmark"
                        labelPosition="left"
                        content="I understand"
                      />
                    </Modal.Actions>
                  </Modal>
                </Menu>
              )}
            {/* Renders the current pane beneath the menu */}
            {this.state.pane}

            {/* Buttons on the lefthand side(for mobile device users) */}
            <DeviceOrientation lockOrientation={'landscape'}>
              <Orientation orientation="landscape" alwaysRender={false}>
                {isMobile && false && (
                  <Menu vertical={true}>
                    <Menu.Item>
                      <Button
                        className="left-menu-button"
                        onClick={() => {
                          if (this.state.openPopUp === 'length') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'length'
                            });
                          }
                          // this.setState(state => ({ isLengthPopUpOpen: !state.isLengthPopUpOpen }));
                        }}
                      >
                        Length
                      </Button>
                    </Menu.Item>
                    {/* length = 1ms * (10/9) ^ slider_value */}
                    {this.state.openPopUp === 'length' && (
                      <div
                        style={{
                          top: '11%',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 1,
                          right: '50px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          <span> &nbsp;Selection Length: </span>
                          <Slider
                            min={0}
                            max={70}
                            value={
                              Math.log(
                                wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                              ) / Math.log(1 / scaleForShorteningSelection)
                            }
                            onChange={value => {
                              wvContainer.updateSelectionLength(
                                audioRecorder,
                                value,
                                scaleForShorteningSelection,
                                audioRecorder.getSampleRate()
                              );

                              this.setState({
                                inputLength: (
                                  ((1 / scaleForShorteningSelection) ** value / 1000) *
                                  1000
                                ).toFixed(2) // display 2 decimal points
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />

                          {/* <span>{wvContainer.getSelectionLengthString()}&nbsp;&nbsp;</span> */}
                        </div>

                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetLengthDisplay()
                                ? wvContainer
                                    .getSelectionLength(audioRecorder.getSampleRate())
                                    .toFixed(2)
                                : //   Math.log(
                                  //     wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                                  //   ) / Math.log(1 / scaleForShorteningSelection)
                                  // ).toFixed(2)
                                  this.state.inputLength
                            }
                            onChange={e => this.handleLengthChange(e, audioRecorder)}
                          ></input>{' '}
                          ms
                        </label>
                        <button
                          onClick={() => {
                            const value =
                              Math.log(this.state.inputLength) /
                              Math.log(1 / scaleForShorteningSelection);
                            wvContainer.updateSelectionLength(
                              audioRecorder,
                              value,
                              scaleForShorteningSelection,
                              audioRecorder.getSampleRate()
                            );
                            wvContainer.setManualPtSelection(false);
                            //console.log("setPointSelectionFlag(false)");
                          }}
                        >
                          enter
                        </button>
                      </div>
                    )}

                    <Menu.Item>
                      <Button
                        onClick={() => {
                          if (this.state.openPopUp === 'speed') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'speed'
                            });
                          }

                          // this.setState(state => ({ isSpeedPopUpOpen: !state.isSpeedPopUpOpen }));
                        }}
                        className="left-menu-button"
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                      >
                        Speed
                      </Button>
                    </Menu.Item>
                    {this.state.openPopUp === 'speed' && (
                      <div
                        style={{
                          top: '11%',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 1,
                          right: '50px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          speed:
                          <Slider
                            min={200}
                            max={4000}
                            value={audioRecorder.getResampleRatio()}
                            onChange={value => {
                              this.resample(audioRecorder, wvContainer, value);
                              this.setState({
                                inputSpeed: audioRecorder.getResampleRatio() / 1000
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />
                          {/* {audioRecorder.getResampleRatio() / 1000} */}
                        </div>

                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetResampleDisplay()
                                ? audioRecorder.getResampleRatio() / 1000
                                : this.state.inputSpeed
                            }
                            onChange={e => this.handleSpeedChange(e, audioRecorder)}
                          ></input>{' '}
                          x
                        </label>
                        <button
                          onClick={() => {
                            this.resample(audioRecorder, wvContainer, this.state.inputSpeed * 1000);
                          }}
                        >
                          enter
                        </button>
                      </div>
                    )}
                    <Menu.Item>
                      <Button
                        onClick={() => {
                          // this.setState(state => ({ isAmplitudePopUpOpen: !state.isAmplitudePopUpOpen }));

                          if (this.state.openPopUp === 'amplitude') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'amplitude'
                            });
                          }
                        }}
                        className="left-menu-button"
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                      >
                        Amplitude
                      </Button>
                    </Menu.Item>
                    {this.state.openPopUp === 'amplitude' && (
                      <div
                        style={{
                          top: '11%',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 1,
                          right: '50px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          amplitude:
                          <Slider
                            min={20}
                            max={4000}
                            value={audioRecorder.getAmplitudeRatio()}
                            onChange={value => {
                              this.changeAmplitude(audioRecorder, wvContainer, value);
                              this.setState({
                                // inputAmplitude: audioRecorder.getAmplitudeRatio() /
                                // 1000
                                inputAmplitude:
                                  constants.AMPLITUDE_LOG_RATIO **
                                  (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />
                          {/* {audioRecorder.getAmplitudeRatio() / 1000} */}
                        </div>
                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetAmplitudeDisplay()
                                ? (
                                    constants.AMPLITUDE_LOG_RATIO **
                                    (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                                  ).toFixed(2)
                                : this.state.inputAmplitude
                            }
                            onChange={e => this.handleAmplitudeChange(e, audioRecorder)}
                          ></input>{' '}
                          x
                        </label>
                        <button
                          onClick={() => {
                            this.changeAmplitude(
                              audioRecorder,
                              wvContainer,
                              this.state.inputAmplitude,
                              true
                            );
                          }}
                        >
                          enter
                        </button>{' '}
                      </div>
                    )}

                    {/* <MenuItem>
                  <Button onClick={() => this.toggleQuantizeSelection()}>Edit Selection</Button>
                </MenuItem> */}
                    <div
                      style={{
                        position: 'absolute',
                        display: this.state.isQuantizeSelectionPopUpOpen ? 'block' : 'none',
                        top: '90px',
                        left: '750px',
                        zIndex: '1',
                        padding: '10px'
                      }}
                    >
                      <div style={{ borderRadius: '.28571429rem' }}>
                        {/* <button onClick={() => this.newSelectionOnClick(wvContainer)}>
                      {' '}
                      new selection{' '}
                    </button> */}
                        <div>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.LEFT)
                            }
                          >
                            {' '}
                            &lt;-{' '}
                          </button>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.RIGHT)
                            }
                          >
                            {' '}
                            -&gt;{' '}
                          </button>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.LONGER)
                            }
                          >
                            longer
                          </button>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.SHORTER)
                            }
                          >
                            shorter
                          </button>
                        </div>
                        {/* <button
                      className="selectionButtons"
                      onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1 / 4)}
                    >
                      1/4s
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1 / 3)}
                    >
                      1/3s
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1 / 2)}
                    >
                      1/2s
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 2 / 3)}
                    >
                      2/3s
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 3 / 4)}
                    >
                      3/4s
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1)}
                    >
                      1s
                    </button> */}
                      </div>
                    </div>

                    <Menu.Item>
                      <Button
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                        onClick={e => this.reverse(audioRecorder, wvContainer)}
                        className="left-menu-button"
                      >
                        Reverse
                      </Button>
                    </Menu.Item>

                    {/* Reverb crashed (even for the previous versions that used to work), so Reverb is temporarily disabled. */}
                    {/*<Menu.Item>
                    <Button
                      toggle
                      disabled={
                        this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0
                      }
                      active={audioRecorder.isReverbOn()}
                      onClick={() => this.applyReverb(audioRecorder, wvContainer)}
                      className="left-menu-button"
                    >
                      Reverb
                    </Button>
                    </Menu.Item>*/}
                  </Menu>
                )}
              </Orientation>
            </DeviceOrientation>
          </div>
        )}
      </Subscribe>
    );
  }
}

export default RightMenu;

import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

import SaveBtn from './SaveBtn';
import UploadBtn from './UploadBtn';

import '../styles/ExportPane.scss';
import '../styles/bottom-menu.scss';

import { toMp3 } from '../../util';
import { constants } from "../../constants";
// import { uploadToS3 } from '../../api';

class ExportPane extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;

    this.onSave = this.onSave.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.reset = this.reset.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  get initialState() {
    return {
      gmailMailto: 'https://mail.google.com/mail/?view=cm&fs=1',
      subject: 'Musiscope Recording',
      body: 'Hey! Listen to my recordings at: ',
      link: ''
    };
  }

  reset() {
    // reset the component state
    this.setState(this.initialState);
  }

  /**
   * Handles action when clicking save button
   * Previously used backend api for saving locally or through email, later on
   * the api no longer works, so the current version directly saves the blob to
   * local file
   * @param {*} audioRecorder
   */
  onSave(audioRecorder) {
    // If exceeds export limit, do nothing but opening the popup warning
    if (audioRecorder.getRecordingDuration() >= constants.REC_DURATION_THRESHOLD_LEN) {
      this.props.openPopupWarn(audioRecorder);
    }else{
    // else, export the mp3 file
    audioRecorder
      .getSumBuffers()
      .then(audioBuffer => toMp3(audioBuffer))
      .then(blob => {
        var reader = new FileReader();
        reader.onload = function(e) {
          window.location.href = reader.result;
        };
        reader.readAsDataURL(blob);
        var bUrl = window.URL.createObjectURL(blob);
        // create hidden HTML button for file download
        var link = document.createElement('a');
        link.href = bUrl;
        link.download = 'recording.mp3';
        link.css = 'display: none';
        link.id = 'downloadLink';
        document.body.appendChild(link);
        document.getElementById('downloadLink').click();
        document.body.removeChild(link);
      });
    }
  }

  /**
   * Handles action when a file is uploaded. 
   * @param {Event} e File input event
   * @param {*} audioRecorder Audio recorder container of oscilloscope
   * @param {*} wvContainer Wave viewer container of oscilloscope
   * @param {*} mContainer Memento container of oscilloscope
   */
  async onUpload(e, audioRecorder, wvContainer, mContainer){
    var fileReader  = new FileReader();
    if(e.target.files.length === 1){
      fileReader.readAsArrayBuffer(e.target.files[0])
    }

    // this function is called when fileReader finishes creating arrayBuffer
    fileReader.onloadend = async () => {
      let audioData = fileReader.result;
      // decode the audioBuffer into a buffer compatible with Tone and the containers
      let newAudioData = await audioRecorder.decodeArrayBuffer(audioData);
      // load new buffer
      // await audioRecorder.createClipFromBuffer(newBuffer, wvContainer);
      audioRecorder.uploadAudioData(newAudioData);
      // take a snapshot
      mContainer.takeSnapshot(audioRecorder, wvContainer);
    }
  }

  render() {
    return (
      <>
        <Menu.Item 
          position="right" 
          className="horiz export-buttons">
          <UploadBtn onUpload={this.onUpload} />
          <SaveBtn onSave={this.onSave} link={this.state.link} />
        </Menu.Item>
      </>
    );
  }
}

export default ExportPane;
